import {
	Alert,
	Backdrop,
	Badge,
	Box,
	Button,
	Divider,
	IconButton,
	MenuItem,
	Modal,
	Popover,
	styled,
	Tooltip,
	Typography,
} from '@mui/material'
import {
	BetaObject,
	FilteredOrderDisplay,
	FilteredOrderResponse,
	FilteredOrderSKUDisplay,
	PostHookObj,
	PublishServiceBusMessageModel,
	SRECreateIfNotExistRequestModel,
	UpdateMSTeamsUserRequest,
} from '../../../../utils/interfaces/APIModels'
import {
	DataGridProProps,
	GridColDef,
	GridFooter,
	GridFooterContainer,
	GridRenderCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useRef, useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined'
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined'
import { StyledDataGrid } from '../../../shared/datagrid/StyledDataGrid'
import './OrderDisplay.scss'
import {
	FilteredOrderStatus,
	OrderFilterKeyMappings,
	Modals,
	OrderStatuses,
	OrderType,
	Permissions,
	ServiceTypes,
} from '../../../../utils/enums/enums'
import IconLoadingButton from '../../../shared/loading/iconButton/IconLoadingButton'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { LoadingButton } from '@mui/lab'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import OrderStatusCardDisplay from './OrderStatusCardDisplay/OrderStatusCardDisplay'
import OrderFilters from './OrderFilters/OrderFilters'
import {
	OCServiceInformationRequest,
	OrderFilter,
} from '../../../../utils/interfaces/ComponentModels'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
	existsInArray,
	showErrorToast,
	showSuccessToast,
	toBetaString,
} from '../../../../utils/helperFunctions/helperFunctions'
import { useAppDispatch } from '../../../../store/hooks'
import {
	resetOrderFilters,
	setCustomerInfoForSREToUse,
	setCustomerOrderInfoToUse,
	setCustomerOrderSKUInfoToUse,
	setOrderFilter,
	setOrderPageNo,
	setOrderPageSize,
	setOrderSKUIDForTrunk,
} from '../../../../store/reducers/reducers'
import { useNavigate } from 'react-router'
import StyledModal from '../../../shared/styledComponents/StyledModal/StyledModal'
import ConfirmOrder from './Modals/ConfirmOrder/ConfirmOrder'
import SREEmergencyCallingDisplay from './Modals/SREEmergencyCalling/SREEmergencyCalling'
import {
	CustomerOrderInfo,
	CustomerOrderSKUInfo,
} from '../../../../utils/interfaces/StateModels'
import {
	CarrierCountryMap,
	Country,
	OrderSKU,
	OrderSKUTerm,
	Service,
	ServiceType,
	TrunkGroup,
} from '../../../../utils/interfaces/DBModels'
import PostAndRetrieveDataHook from '../../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import AddTNs from './Modals/AddTNs/AddTNs'
import OrderSKUPriceUpdate from './Modals/OrderSKUPriceUpdate/OrderSKUPriceUpdate'
import PostUpdateDataHook from '../../../../utils/customHooks/APICalls/PostUpdateDataHook'
import EditIcon from '@mui/icons-material/Edit'
import OrderSKUTermUpdate from './Modals/OrderSKUTermUpdate/OrderSKUTermUpdate'
import ResendAuth0Invites from './Modals/ResendAuth0Invites/ResendAuth0Invites'
import ViewOrderNotes from './Modals/ViewOrderNotes/ViewOrderNotes'
import AutoRenewUpdate from './Modals/AutoRenewUpdate/AutoRenewUpdate'
import CustomToolbar from '../../../shared/datagrid/CustomToolbar'
import AssignTNAddresses from './Modals/AssignTNAddresses/AssignTNAddresses'
import {
	BaseOCServiceProduct,
	FailedProductActionStatus,
	InProgressProductActionStatus,
	OCServiceInformationProduct,
} from '../../../../utils/constants/constants'
import OCServiceInformation from './Modals/AddOCServiceInformation/OCServiceInformation'
import { usePublishServiceBusMessageMutation } from '../../../../services/proxyAPIData'
import ErrorLogging from '../../../../utils/customHooks/ErrorLogging'
import ChangeOrderType from './Modals/ChangeOrderType/ChangeOrderType'

// Custom Box Style for the edit price fields
const customBoxPriceStyle = {
	display: 'flex',
	flexDirection: 'row',
	columnGap: '5px',
	alignItems: 'center',
}

//Custom styling
const InfoAlert = styled(Alert)({
	'& .MuiAlert-icon': {
		color: '#0075c9',
	},
	backgroundColor: '#E0F3FF',
	color: 'black',
	fontSize: '14px',
})

const OrderDisplay = ({
	orderResponse,
	callStatus,
	pageNo,
	pageSize,
	// API Calls
	getOrderDisplay,
	confirmOrder,
}: {
	orderResponse: FilteredOrderResponse
	callStatus: number
	pageNo: number
	pageSize: number
	// API Calls
	getOrderDisplay: any
	confirmOrder: any
}) => {
	const [publishServiceBusMessage] = usePublishServiceBusMessageMutation()

	// Error logging
	const { addErrorLog } = ErrorLogging()

	// Global variables
	const loggedInRoleAndPermissions = useSelector(
		(state: RootState) =>
			state.RootReducer.loggedInUserRolesPermissionsReducer.value
	)

	//use this flag to show or hide component
	const isPartnerAdmin = useSelector(
		(state: RootState) => state.RootReducer.isPartnerAdminReducer.value
	)

	// Display constants
	const [orderRows, setOrderRows] = useState([] as FilteredOrderDisplay[])
	const [currentPageNo, setCurrentPageNo] = useState(0)
	const [currentPageSize, setCurrentPageSize] = useState(0)
	const [totalOrderCount, setTotalOrderCount] = useState(0)
	const [totalOrderPages, setTotalOrderPages] = useState(0)

	// Logic constants
	//  -- Order -- //
	const [orderID, setOrderID] = useState(0)
	const [orderNotes, setOrderNotes] = useState('')
	const [orderStatusID, setOrderStatusID] = useState('')
	const _serviceID = useRef('')

	// Flags
	const [navLoadingType, setNavLoadingType] = useState('')
	const [filterLoading, setFilterLoading] = useState(false)

	// Modal Logic
	const [openOrderModal, setOpenOrderModal] = useState(false)

	// Filters
	const [orderFilterQuery, setOrderFilterQuery] = useState(
		{} as Record<string, string>
	)
	const [orderFilters, setOrderFilters] = useState([] as OrderFilter[])
	const [filterAnchorEl, setFilterAnchorEl] =
		useState<HTMLButtonElement | null>(null)
	const openFilter = Boolean(filterAnchorEl)
	const filterID = openFilter ? 'simple-popover' : undefined

	// General
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	// Modal Logic
	const [modalType, setModalType] = useState(0)
	const [modalWidth, setModalWidth] = useState(0)
	const [orderAnchorEl, setOrderAnchorEl] = useState<null | HTMLElement>(null)
	const openOrderMenu = Boolean(orderAnchorEl)

	const [columns, setColumns] = useState<GridColDef[]>([])

	// Handle open of order option menu
	const openOrderOptionsMenu = (
		e: React.MouseEvent<HTMLButtonElement>,
		id: number,
		row: FilteredOrderDisplay
	) => {
		setOrderAnchorEl(e.currentTarget)
		setOrderID(id)
		setOrderNotes(row.Comments + '')
		setOrderStatusID(row.OrderStatusID + ' ')
	}

	// Handle close of order option menu
	const handleCloseOrderMenu = () => {
		setOrderAnchorEl(null)
	}

	// Open modal for DDIs
	const handleOpenOrderModal = async (modalType: number) => {
		setModalWidth(300)
		setModalType(modalType)
		setOpenOrderModal(true)
		handleCloseOrderMenu()
	}

	// Close modal
	const handleCloseOrderModal = () => {
		setModalType(0)
		setOpenOrderModal(false)
	}

	// Column Definition: Order Display
	const orderColumns: GridColDef[] = [
		{
			field: 'ExternalOrderID',
			headerName: 'Order ID',
			hideable: false,
			flex: 1,
		},
		{
			field: 'CustomerPartnerReference',
			headerName: 'BizOrg',
			hideable: false,
			flex: 1,
		},
		{
			field: 'OrderStatus',
			headerName: 'Order Status',
			hideable: false,
			flex: 1,
		},
		{
			field: 'OrderType',
			headerName: 'Order Type',
			hideable: false,
			flex: 1,
		},
		{
			field: 'PurchaseOrder',
			headerName: 'Purchase Order',
			hideable: true,
			flex: 1,
		},
		{
			field: 'PartnerOrderID',
			headerName: 'Partner Order ID',
			hideable: true,
			flex: 1,
		},
	]

	useEffect(() => {
		// Check response
		if (orderResponse) {
			// Set all values
			setTotalOrderCount(Number(orderResponse.TotalCount))
			setTotalOrderPages(Number(orderResponse.TotalPages))
			setOrderRows(
				orderResponse.FilteredOrdersResponse as FilteredOrderDisplay[]
			)
		}
		// Current page
		if (pageNo) {
			setCurrentPageNo(pageNo)
			dispatch(setOrderPageNo(pageNo))
		}
		if (pageSize) {
			setCurrentPageSize(pageSize)
			dispatch(setOrderPageSize(pageSize))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderResponse, pageNo, pageSize])

	useEffect(() => {
		// Add Remove columns
		if (isPartnerAdmin) {
			orderColumns.splice(2, 0, {
				field: 'CustomerName',
				headerName: 'Customer Name',
				hideable: false,
				flex: 1,
			})
		}

		setColumns(orderColumns)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Custom Components
	// 1 - Custom Footer
	function CustomFooter() {
		return (
			<GridFooterContainer className='order-footer'>
				<GridFooter sx={{ borderTop: 'none' }} />
				<Box className='order-footer-content'>
					{/* Total rows */}
					<Typography component='p'>
						Page <strong>{currentPageNo}</strong> - {totalOrderPages} of{' '}
						{totalOrderCount} Results
					</Typography>
					{/* Pagination */}
					<Box className='order-pagination'>
						{/* Skip to first page */}
						<Tooltip title='Skip to first page'>
							<span>
								<IconLoadingButton
									disabled={currentPageNo === 1}
									loading={navLoadingType === 'first-page'}
									icon={<SkipPreviousOutlinedIcon />}
									onClick={() => handlePageNavigation('first-page')}
								/>
							</span>
						</Tooltip>
						{/* Previous */}
						<Tooltip title='Previous page'>
							<span>
								<IconLoadingButton
									disabled={currentPageNo === 1}
									loading={navLoadingType === 'prev'}
									icon={<NavigateBeforeIcon />}
									onClick={() => handlePageNavigation('prev')}
								/>
							</span>
						</Tooltip>
						{/* Next */}
						<Tooltip title='Next Page'>
							<span>
								<IconLoadingButton
									disabled={currentPageNo === totalOrderPages}
									loading={navLoadingType === 'next'}
									icon={<NavigateNextIcon />}
									onClick={() => handlePageNavigation('next')}
								/>
							</span>
						</Tooltip>
						{/* Skip to lasy page */}
						<Tooltip title='Skip to last page'>
							<span>
								<IconLoadingButton
									disabled={currentPageNo === totalOrderPages}
									loading={navLoadingType === 'last-page'}
									icon={<SkipNextOutlinedIcon />}
									onClick={() => handlePageNavigation('last-page')}
								/>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</GridFooterContainer>
		)
	}

	// 2 - Order SKU Display
	function OrderSKUDisplay({ row: rowProp }: { row: FilteredOrderDisplay }) {
		// Global variables
		const loggedInUser = useSelector(
			(state: RootState) => state.RootReducer.loggedInUserReducer.value
		)
		const skuPageNo = useSelector(
			(state: RootState) => state.RootReducer.orderFilterReducer.pageNo
		)
		const skuPageSize = useSelector(
			(state: RootState) => state.RootReducer.orderFilterReducer.pageSize
		)
		const skuOrderFilter = useSelector(
			(state: RootState) => state.RootReducer.orderFilterReducer.filters
		)

		// Hooks
		const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()
		const { postUpdateDataWithoutRefetch } = PostUpdateDataHook()

		// Logic Constants
		const [customerID, setCustomerID] = useState('')
		const [orderSKUID, setOrderSKUID] = useState(0)
		const [orderSKUAnchorEl, setOrderSKUAnchorEl] =
			useState<null | HTMLElement>(null)
		const openOrderSKUMenu = Boolean(orderSKUAnchorEl)

		// Add TN Range Arrays
		const [countryDropdownList, setCountryDropdownList] = useState(
			[] as Country[]
		)
		const [serviceTypeDropdownList, setServiceTypeDropdownList] = useState(
			[] as ServiceType[]
		)
		const [customerServiceList, setCustomerServiceList] = useState(
			[] as Service[]
		)
		const [carrierCountryMapList, setCarrierCountryMapList] = useState(
			[] as CarrierCountryMap[]
		)
		const [trunkGroupList, setTrunkGroupList] = useState([] as TrunkGroup[])

		// SKU Price and Term Update Constants
		const [skuTypeID, setSKUTypeID] = useState('')
		const [priceValue, setPriceValue] = useState(0)
		const [termValue, setTermValue] = useState(0)
		const [autoRenew, setAutoRenew] = useState(false)

		// Modal Logic
		const [modalType, setModalType] = useState(0)
		const [openOrderSKUModal, setOpenOrderSKUModal] = useState(false)
		const [modalWidth, setModalWidth] = useState(0)

		const [orderSKUColumns, setOrderSKUColumns] = useState<GridColDef[]>([])

		// Column Definition: Order Display
		const skuColumns: GridColDef[] = [
			{
				field: 'OrderSKUID',
				headerName: 'Order SKU ID',
				width: 100,
				hideable: false,
				flex: 1,
			},
			{
				field: 'SKUTypeID',
				headerName: 'SKU',
				width: 100,
				hideable: false,
				flex: 1,
			},
			{
				field: 'Quantity',
				headerName: 'Quantity',
				width: 100,
				hideable: false,
				flex: 1,
			},
			{
				field: 'OrderSKUTerm',
				headerName: 'SKU Term',
				flex: 1,
				width: 100,
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
					<>
						<Box sx={customBoxPriceStyle}>
							{/* Value */}
							<Typography component='p'>
								{Number(params?.row.Price + '') === 0
									? 'No Term'
									: displayOrderTerm(
											Number(params.row.SKUYears),
											Number(params.row.SKUMonths)
									  )}
							</Typography>
							{isPartnerAdmin && canUpdateSKUTerm(params.row) && (
								<Tooltip title='Update Order SKU Term'>
									<IconButton
										onClick={() =>
											handleOpenOrderSKUModal(
												Number(params.id),
												Modals.SKUTermUpdate
											)
										}>
										<EditIcon />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</>
				),
			},
			{
				field: 'AutoRenew',
				headerName: 'AutoRenew',
				flex: 1,
				width: 100,
				renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
					<>
						<Box sx={customBoxPriceStyle}>
							{/* Value */}
							<Typography component='p'>
								{params.row.AutoRenew == null
									? 'Not Set'
									: params.row.AutoRenew + ''}
							</Typography>
							{isPartnerAdmin &&
								rowProp.OrderStatusID + '' ===
									OrderStatuses.AwaitingPartnerApproval && (
									<Tooltip title='Update'>
										<IconButton
											onClick={() =>
												handleOpenOrderSKUModal(
													Number(params.id),
													Modals.AutoRenewUpdate
												)
											}>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
						</Box>
					</>
				),
			},
			{
				field: 'OrderSKUStatus',
				headerName: 'SKU Status',
				width: 100,
				hideable: false,
				flex: 1,
			},
			{
				field: 'ActivationDate',
				renderHeader: () => (
					<>
						<Tooltip title='Activation Date of the first SKU of each Order is deemed to be the Commencement Date'>
							<InfoAlert severity='info' style={{ padding: '0' }}>
								{'Activation Date'}
							</InfoAlert>
						</Tooltip>
					</>
				),
				width: 100,
				hideable: false,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => (
					<>
						{params.value ? (
							<>
								<Typography component='p'>
									{new Date(params.value).toLocaleDateString('en-US')}{' '}
								</Typography>
							</>
						) : (
							''
						)}
					</>
				),
			},
			{
				field: 'CurrentProduct',
				headerName: 'Current Product',
				width: 120,
				hideable: false,
				flex: 1,
			},
		]

		useEffect(() => {
			// Add Remove columns
			if (isPartnerAdmin) {
				// Add NRC column at postion 3
				skuColumns.splice(3, 0, {
					field: 'Price',
					headerName: 'MRC/Unit',
					flex: 1,
					width: 100,
					renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
						<>
							<Box sx={customBoxPriceStyle}>
								{/* Value */}
								<Typography component='p'>{params.value + ''}</Typography>
								{rowProp.OrderStatusID + '' ===
									OrderStatuses.AwaitingPartnerApproval &&
									Number(params.value) > 0 && (
										<Tooltip title='Update Order SKU Price'>
											<IconButton
												onClick={() =>
													handleOpenOrderSKUModal(
														Number(params.id),
														Modals.SKUPriceUpdate
													)
												}>
												<EditIcon />
											</IconButton>
										</Tooltip>
									)}
							</Box>
						</>
					),
				})
				// Add NRC column at postion 4
				skuColumns.splice(4, 0, {
					field: 'NRC',
					headerName: 'NRC/Unit',
					flex: 1,
					width: 100,
					renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
						<>
							<Box sx={customBoxPriceStyle}>
								{/* Value */}
								<Typography component='p'>{params.value}</Typography>
								{rowProp.OrderStatusID + '' ===
									OrderStatuses.AwaitingPartnerApproval && (
									<Tooltip title='Update Order SKU NRC'>
										<IconButton
											onClick={() =>
												handleOpenOrderSKUModal(
													Number(params.id),
													Modals.SKUNRCUpdate
												)
											}>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						</>
					),
				})
			}

			setOrderSKUColumns(skuColumns)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		// GET: Data for Adding TNs
		const fetchDataForAddingTNs = async () => {
			var dataResponse = await postAndRetrieveDataFromDB(
				'Country List, Services List',
				`Country.Where(Country.IsActive='true'), Service.Where(Service.ServiceID ~ '${rowProp.CustomerID}'), ServiceType.All(),
				CarrierCountryMap.All(), TrunkGroup.CustomerTrunkGroupIP.CustomerTrunkGroupRegionMap.Includes(TrunkGroup.CustomerID = '${rowProp.CustomerID}')`,
				true,
				true
			)

			if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
				var countryList = dataResponse.Obj.CountryList as Country[]
				var serviceTypeList = dataResponse.Obj.ServiceTypeList as ServiceType[]
				var serviceList = dataResponse.Obj.ServiceList as Service[]
				var carrierCountryMapList = dataResponse.Obj
					.CarrierCountryMapList as CarrierCountryMap[]
				var trunkGroupList = dataResponse.Obj.TrunkGroupList as TrunkGroup[]

				setCountryDropdownList(
					countryList.sort((a, b) =>
						a.CountryName!.localeCompare(b.CountryName!, undefined, {
							sensitivity: 'base',
						})
					)
				)
				setServiceTypeDropdownList(
					serviceTypeList.sort((a, b) =>
						a.ServiceTypeName!.localeCompare(b.ServiceTypeName!, undefined, {
							sensitivity: 'base',
						})
					)
				)
				setCustomerServiceList(
					serviceList.sort((a, b) =>
						a.ServiceID!.localeCompare(b.ServiceID!, undefined, {
							sensitivity: 'base',
						})
					)
				)
				setCarrierCountryMapList(carrierCountryMapList)

				setTrunkGroupList(trunkGroupList)
			}
		}

		// GET: Order Term ID
		const getSKUTermID = async (orderSKUID: number) => {
			// Hook call
			var dataResponse = await postAndRetrieveDataFromDB(
				'Get Order SKU Term ID',
				`OrderSKUTerm.First(OrderSKUTerm.OrderSKUID = '${orderSKUID}')`,
				true,
				true
			)
			if (dataResponse && dataResponse.Obj) {
				var orderSKUTermResponse = dataResponse.Obj.OrderSKUTerm as OrderSKUTerm
				if (orderSKUTermResponse && orderSKUTermResponse.OrderSKUTermID) {
					// Return ID
					return orderSKUTermResponse.OrderSKUTermID
				}
			}

			// Empty return
			return 0
		}

		// POST: Update price/nrc value
		const updateSKUPrice = async (orderSKUObj: OrderSKU[]) => {
			// Create object
			var updateSKUPriceObj = {
				OrderSKU: orderSKUObj,
			}

			// Value update
			var valueUpdating = modalType === Modals.SKUPriceUpdate ? 'MRC' : 'NRC'

			var postHookObj: PostHookObj = {
				Action: `Update Order ${valueUpdating} Price`,
				LoggedInUser: loggedInUser,
				RequestURL: 'UpdateV2?Params=OrderSKU',
				RequestObj: updateSKUPriceObj,
				ShowSuccessMessage: true,
				SuccessMessage: `Order ${valueUpdating} Price has been updated successfully`,
				ShowErrorMessage: false,
				ErrorMessage: `An error occurred when updating the order ${valueUpdating} price`,
				LogErrorToDB: true,
			}

			try {
				await postUpdateDataWithoutRefetch(postHookObj)
				await getOrderDisplay({
					pageNo: skuPageNo,
					pageSize: skuPageSize,
					filters: skuOrderFilter,
				})
			} catch (error) {
				// Show error message
				showErrorToast('An error occurred when trying to update SKU price')
			}
		}

		const updateSKUAutoRenew = async (orderSKUObj: OrderSKU) => {
			// Create object
			var updateSKUObj = {
				OrderSKU: orderSKUObj,
			}

			var postHookObj: PostHookObj = {
				Action: `Update Order SKU AutoRenew`,
				LoggedInUser: loggedInUser,
				RequestURL: 'UpdateV2?Params=OrderSKU',
				RequestObj: updateSKUObj,
				ShowSuccessMessage: true,
				SuccessMessage: `Order SKU has been updated successfully`,
				ShowErrorMessage: false,
				ErrorMessage: `An error occurred when updating the order SKU`,
				LogErrorToDB: true,
			}

			try {
				await postUpdateDataWithoutRefetch(postHookObj)
				await getOrderDisplay({
					pageNo: skuPageNo,
					pageSize: skuPageSize,
					filters: skuOrderFilter,
				})
			} catch (error) {
				// Show error message
				showErrorToast('An error occurred when trying to update SKU')
			}
		}

		// POST: Update SKU Term
		const updateSKUTerm = async (
			orderSKUTermObj: OrderSKUTerm[],
			requestURL: string
		) => {
			// Create object
			var updateSKUPriceObj = {
				OrderSKUTerm: orderSKUTermObj,
			}

			var postHookObj: PostHookObj = {
				Action: `Update Order SKU Term`,
				LoggedInUser: loggedInUser,
				RequestURL: requestURL,
				RequestObj: updateSKUPriceObj,
				ShowSuccessMessage: false,
				SuccessMessage: `Order SKU Term has been updated successfully`,
				ShowErrorMessage: false,
				ErrorMessage: `An error occurred when updating the order SKU term`,
				LogErrorToDB: true,
			}

			try {
				await postUpdateDataWithoutRefetch(postHookObj)
				await getOrderDisplay({
					pageNo: skuPageNo,
					pageSize: skuPageSize,
					filters: skuOrderFilter,
				})
				// Show Success
				showSuccessToast('Order SKU Term has been updated successfully')
			} catch (error) {
				// Show error message
				showErrorToast('An error occurred when trying to update SKU term')
			}
		}

		// Handle open of order SKUs option menu
		const openOrderSKUOptionsMenu = (
			e: React.MouseEvent<HTMLButtonElement>,
			orderSKUID: number
		) => {
			setOrderSKUAnchorEl(e.currentTarget)
			setOrderSKUID(orderSKUID)
		}

		// Handle close of order option menu
		const handleCloseOrderSKUMenu = () => {
			setOrderSKUAnchorEl(null)
		}

		// Check if contains the DDI and Trunk Product
		const showMenuAndItems = (
			type: string,
			row: FilteredOrderSKUDisplay,
			rowProp: FilteredOrderDisplay
		) => {
			// Return var
			var showItem = false
			// Check row for flags

			var ordStatus = rowProp?.OrderStatusID + ''
			if (
				ordStatus !== OrderStatuses.InProgress &&
				ordStatus !== OrderStatuses.Active &&
				ordStatus !== OrderStatuses.Complete
			) {
				return false
			}

			if (
				(type === 'ALL' || type === 'DDI') &&
				row.DDIProductExists &&
				existsInArray(
					loggedInRoleAndPermissions,
					'PermissionID',
					Permissions.AddDDI
				)
			) {
				showItem = true
			}

			if (
				(type === 'ALL' || type === 'BYOC') &&
				(row.BYOCProductExists || row.BaseBYOCProductExists) &&
				existsInArray(
					loggedInRoleAndPermissions,
					'PermissionID',
					Permissions.AddTrunkGroups
				)
			) {
				showItem = true
			}

			if ((type === 'ALL' || type === 'PORT_INV') && row.PortInvProductExists) {
				showItem = true
			}

			if (
				type === 'OC' &&
				existsInArray(
					loggedInRoleAndPermissions,
					'PermissionID',
					Permissions.AddTrunkGroups
				) &&
				row.OrderSKUProducts?.find(
					(product) => product.ProductID === OCServiceInformationProduct
				)
			) {
				showItem = true
			}

			return showItem
		}

		const canAddOCServiceInformation = (row: FilteredOrderSKUDisplay) => {
			var ocServiceInformationProduct = row.OrderSKUProducts?.find(
				(product) => product.ProductID === OCServiceInformationProduct
			)
			if (
				ocServiceInformationProduct &&
				(ocServiceInformationProduct.ProductActionStatusID + '' ===
					InProgressProductActionStatus ||
					ocServiceInformationProduct.ProductActionStatusID + '' ===
						FailedProductActionStatus)
			) {
				return existsInArray(
					loggedInRoleAndPermissions,
					'PermissionID',
					Permissions.AddTrunkGroups
				)
			}
			return false
		}

		// NICO WILL USE THIS LATER
		// const hasTeamsService = (orderSKUID: number) => {
		// 	var matchingOrder = orderRows.find((x) =>
		// 		x.OrderSKUs?.find((y) => y.OrderSKUID === orderSKUID)
		// 	)
		// 	var hasValidOrderStatus =
		// 		matchingOrder?.OrderStatusID + '' === OrderStatuses.InProgress ||
		// 		OrderStatuses.Active ||
		// 		OrderStatuses.Complete

		// 	// Look in order SKUs
		// 	var matchingOrderSKUProductMapList = orderSKUProductMapList.filter(
		// 		(x) => x.OrderSKUID === orderSKUID
		// 	)
		// 	if (matchingOrderSKUProductMapList) {
		// 		// Find
		// 		if (
		// 			matchingOrderSKUProductMapList.find(
		// 				(x) => x.ProductID === 'BAS-MSTDR' && (x.ServiceID + '').length > 0
		// 			) &&
		// 			hasValidOrderStatus
		// 		) {
		// 			return true
		// 		}
		// 	}

		// 	return false
		// }

		// Nav to trunks
		const navigateToTrunks = () => {
			// Pass the orderSKUID and navigate to trunk page
			dispatch(setOrderSKUIDForTrunk(orderSKUID))
			navigate(`/dashboard/add-trunk/${rowProp.CustomerID + ''}`)
		}

		// Open modal for DDIs
		const handleOpenOrderSKUModal = async (
			orderSKUID: number,
			modalType: number
		) => {
			// Dispatch to state
			setOrderSKUID(orderSKUID)
			await handleOpenModalTypes(orderSKUID, modalType)
			setOpenOrderSKUModal(true)
		}

		// Close modal
		const handleCloseOrderSKUModal = () => {
			setModalType(0)
			setOpenOrderSKUModal(false)
			// Reset modal width incase AssignTNAddresses was displayed
			if (isPartnerAdmin) {
				setModalWidth(300)
			}
		}

		// Handle type modal - types to set
		const handleOpenModalTypes = async (
			orderSKUID: number,
			modalType: number
		) => {
			// Switch on type
			switch (modalType) {
				// Resend invites
				case Modals.ResendAuth0Invites:
					// Dispatch to global state
					var customerOrderInfoToUse: CustomerOrderInfo = {
						CustomerID: rowProp.CustomerID + '',
						OrderID: Number(rowProp.OrderID),
					}
					dispatch(setCustomerOrderInfoToUse(customerOrderInfoToUse))
					setModalWidth(300)
					break
				// SRE Emergency Calling
				case Modals.E911Configurations:
					// Dispatch to global state
					var customerInfoForSREToUse: SRECreateIfNotExistRequestModel = {
						CustomerID: rowProp.CustomerID + '',
						CustomerName: rowProp.CustomerName + '',
					}
					dispatch(setCustomerInfoForSREToUse(customerInfoForSREToUse))
					setModalWidth(400)
					break
				// DDI
				case Modals.AddTN:
					// Dispatch to global state
					var customerOrderSKUInfoToUse: CustomerOrderSKUInfo = {
						CustomerID: rowProp.CustomerID + '',
						OrderSKUID: orderSKUID,
					}
					dispatch(setCustomerOrderSKUInfoToUse(customerOrderSKUInfoToUse))

					// Fetch data
					await fetchDataForAddingTNs()
					setModalWidth(700)
					break
				// SKU Price/MRC, NRC or Term Update Form
				case Modals.SKUPriceUpdate:
				case Modals.SKUNRCUpdate:
				case Modals.SKUTermUpdate:
				case Modals.AutoRenewUpdate:
					// Get the SKUType ID and price values
					var orderSKUList = rowProp.OrderSKUs as FilteredOrderSKUDisplay[]
					var orderSKURow = orderSKUList.find(
						(sku) => Number(sku.OrderSKUID) === orderSKUID
					)
					if (orderSKURow) {
						// Set values
						setSKUTypeID(orderSKURow.SKUTypeID + '')

						// Check which one
						if (modalType === Modals.SKUPriceUpdate) {
							// Price/MRC
							setPriceValue(Number(orderSKURow.Price))
						} else if (modalType === Modals.SKUNRCUpdate) {
							// NRC
							setPriceValue(Number(orderSKURow.NRC))
						} else if (modalType === Modals.SKUTermUpdate) {
							// Term
							var termInMonths = 0
							// Years
							if (orderSKURow.SKUYears) {
								termInMonths += orderSKURow.SKUYears * 12
							}
							// Months
							if (orderSKURow.SKUMonths) {
								termInMonths += orderSKURow.SKUMonths
							}
							setTermValue(termInMonths)
						} else if (modalType === Modals.AutoRenewUpdate) {
							setAutoRenew(orderSKURow.AutoRenew ?? false)
						}
					}
					setModalWidth(300)
					break
				case Modals.OCServiceInformation:
					var filteredOrderSKUList =
						rowProp.OrderSKUs as FilteredOrderSKUDisplay[]
					var orderSKUProducts = filteredOrderSKUList.find(
						(sku) => sku.OrderSKUID === orderSKUID
					)?.OrderSKUProducts

					if (orderSKUProducts && orderSKUProducts.length > 0) {
						var ocServiceInformationProduct = orderSKUProducts.find(
							(product) => product.ProductID === BaseOCServiceProduct
						)

						if (ocServiceInformationProduct) {
							_serviceID.current = ocServiceInformationProduct.ServiceID + ''
						}
					}
					setModalWidth(700)
					break
			}

			// Set modal type and customer ID
			setCustomerID(rowProp.CustomerID + '')
			setModalType(modalType)
		}

		const handleOCServiceInformationAction = async (
			ocServiceInfo: OCServiceInformationRequest
		) => {
			var _teamsServiceToUpdate: UpdateMSTeamsUserRequest = {
				ServiceID: ocServiceInfo.ServiceID,
				ServiceTypeID: ServiceTypes.MSTeamsOperatorConnect,
				TenantID: ocServiceInfo.TenantID,
			}

			var teamsObj = {
				MSTeamsUser: _teamsServiceToUpdate,
			}

			var updateMSTeamsUserPostHookObj: PostHookObj = {
				RequestURL: 'UpdateV2?Params=MSTeamsUser',
				RequestObj: teamsObj,
				ShowErrorMessage: true,
				ErrorMessage: 'An error occurred when updating MS Teams Service',
				Action: `Update MS Teams Service`,
				LoggedInUser: loggedInUser,
				ShowSuccessMessage: false,
				SuccessMessage: `MS Teams Service has been updated successfully`,
				LogErrorToDB: true,
			}

			try {
				await postUpdateDataWithoutRefetch(updateMSTeamsUserPostHookObj)
				// publish action complete here if its successful
				await publishActionCompleteMessage(ocServiceInfo)
				await getOrderDisplay({
					pageNo: skuPageNo,
					pageSize: skuPageSize,
					filters: skuOrderFilter,
				})
				showSuccessToast('OC Service Information updated successfully')
				handleCloseOrderSKUModal()
			} catch (error) {}
		}

		// Check if user can update sku term
		const canUpdateSKUTerm = (row: FilteredOrderSKUDisplay): boolean => {
			if (row?.Price && Number(row.Price) === 0) {
				return false
			}

			// If the Order Type is Modify or TrueUp, return false
			if (
				rowProp.OrderType === OrderType[OrderType.Modify] ||
				rowProp.OrderType === OrderType[OrderType.TrueUp]
			) {
				return false
			}

			// Otherwise, check if Order Status is Awaiting Partner Approval and MRC > 0
			return (
				rowProp.OrderStatusID === OrderStatuses.AwaitingPartnerApproval &&
				Number(row.Price) > 0
			)
		}

		return rowProp.OrderSKUs && rowProp.OrderSKUs.length > 0 ? (
			<Box id='order-sku-info'>
				<StyledDataGrid
					sx={{
						py: 5,
						height: 1,
						boxSizing: 'border-box',
						width: '90%',
						margin: '0px 50px',
					}}
					initialState={{
						columns: {
							columnVisibilityModel: {
								// Hide OrderSKUID
								OrderSKUID: false,
							},
						},
					}}
					columns={[
						...orderSKUColumns,
						{
							field: 'actions',
							type: 'actions',
							cellClassName: 'actions',
							headerName: 'Actions',
							width: 100,
							editable: false,
							align: 'center',
							hideable: false,
							getActions: ({ id, row }) => {
								return [
									<>
										{(isPartnerAdmin &&
											showMenuAndItems('ALL', row, rowProp)) ||
										(!isPartnerAdmin &&
											rowProp.SipcomDirect &&
											row.DDIProductExists) ? (
											<>
												<Tooltip title='Order SKU Options'>
													<IconButton
														id='order-sku-options-button'
														onClick={(e) =>
															openOrderSKUOptionsMenu(e, Number(id))
														}>
														<SettingsIcon />
													</IconButton>
												</Tooltip>
												<Popover
													className='order-options-menu'
													id='order-sku-options-menu'
													anchorEl={orderSKUAnchorEl}
													open={openOrderSKUMenu && orderSKUID === Number(id)}
													onClose={handleCloseOrderSKUMenu}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'center',
													}}
													transformOrigin={{
														vertical: 'top',
														horizontal: 'center',
													}}>
													<>
														{/* Only display for CustomerAdmin with SipcomDirect + DDIProductExists SKUOrder */}
														{!isPartnerAdmin && (
															<MenuItem
																onClick={() => {
																	// Set modal width
																	setModalWidth(1000)
																	handleOpenOrderSKUModal(
																		orderSKUID,
																		Modals.AssignTNAddresses
																	)
																}}>
																Assign Number Addresses
															</MenuItem>
														)}

														{/* PartnerAdmin Menu  */}
														{isPartnerAdmin && (
															<>
																{/* TN */}
																{showMenuAndItems('DDI', row, rowProp) && (
																	<MenuItem
																		onClick={() =>
																			handleOpenOrderSKUModal(
																				orderSKUID,
																				Modals.AddTN
																			)
																		}>
																		Add Numbers
																	</MenuItem>
																)}
																{/* Trunk */}
																{showMenuAndItems('BYOC', row, rowProp) && (
																	<MenuItem onClick={navigateToTrunks}>
																		Add Trunk
																	</MenuItem>
																)}
																{/* E911 */}
																{/* NICO NEEDS TO UPDATE THIS */}
																{/* {hasTeamsService(orderSKUID) && (
														<MenuItem
															onClick={() =>
																handleOpenOrderSKUModal(
																	orderSKUID,
																	Modals.E911Configurations
																)
															}>
															E911 Configuration
														</MenuItem>
													)} */}
																{/* Resend Invites */}
																{showMenuAndItems('PORT_INV', row, rowProp) && (
																	<MenuItem
																		onClick={() =>
																			handleOpenOrderSKUModal(
																				orderSKUID,
																				Modals.ResendAuth0Invites
																			)
																		}>
																		Resend invites
																	</MenuItem>
																)}

																{/* OC */}
																{showMenuAndItems('OC', row, rowProp) && (
																	<MenuItem
																		disabled={!canAddOCServiceInformation(row)}
																		onClick={() =>
																			handleOpenOrderSKUModal(
																				orderSKUID,
																				Modals.OCServiceInformation
																			)
																		}>
																		OC Service Information
																	</MenuItem>
																)}
															</>
														)}
													</>
												</Popover>
											</>
										) : (
											<Tooltip title='This order SKU cannot be modified at this moment.'>
												<DoNotDisturbIcon />
											</Tooltip>
										)}
									</>,
								]
							},
						},
					]}
					rows={rowProp.OrderSKUs as FilteredOrderSKUDisplay[]}
					getRowId={(row) => row.OrderSKUID}
					autoPageSize={true}
					autoHeight={true}
					hideFooter
				/>
				{/* Modal */}
				<Modal open={openOrderSKUModal} onClose={handleCloseOrderSKUModal}>
					<StyledModal width={modalWidth}>
						{/* E911 Configurations */}
						{modalType === Modals.E911Configurations && (
							<SREEmergencyCallingDisplay />
						)}
						{/* Resend invites */}
						{modalType === Modals.ResendAuth0Invites && <ResendAuth0Invites />}
						{/* Add TN */}
						{modalType === Modals.AddTN && (
							<AddTNs
								customerID={customerID}
								orderSKUID={orderSKUID}
								countryDropdownList={countryDropdownList}
								customerServiceList={customerServiceList}
								serviceTypeDropdownList={serviceTypeDropdownList}
								handleCloseMenuAndModal={handleCloseOrderSKUModal}
								carrierCountryMapList={carrierCountryMapList}
								trunkGroupList={trunkGroupList}
							/>
						)}
						{/* Assign TN Addresses */}
						{modalType === Modals.AssignTNAddresses && (
							<AssignTNAddresses
								customerID={customerID}
								orderSKUID={orderSKUID}
								handleCloseMenuAndModal={handleCloseOrderSKUModal}
							/>
						)}
						{/* SKU Price and NRC Update */}
						{(modalType === Modals.SKUPriceUpdate ||
							modalType === Modals.SKUNRCUpdate) && (
							<OrderSKUPriceUpdate
								formType={modalType}
								orderID={rowProp.ExternalOrderID + ''}
								orderSKUID={orderSKUID}
								skuTypeID={skuTypeID}
								currentPriceValue={priceValue}
								updateSKUPrice={updateSKUPrice}
							/>
						)}
						{/* SKU Term Update */}
						{modalType === Modals.SKUTermUpdate && (
							<OrderSKUTermUpdate
								orderID={rowProp.ExternalOrderID + ''}
								orderSKUID={orderSKUID}
								skuTypeID={skuTypeID}
								currentTermValue={termValue}
								getSKUTermID={getSKUTermID}
								updateSKUTerm={updateSKUTerm}
							/>
						)}
						{/* Auto Renew Update */}
						{modalType === Modals.AutoRenewUpdate && (
							<AutoRenewUpdate
								orderID={rowProp.ExternalOrderID + ''}
								orderSKUID={orderSKUID}
								skuTypeID={skuTypeID}
								currentAutoRenewValue={autoRenew}
								updateAutoRenew={updateSKUAutoRenew}
							/>
						)}

						{/* OC Service Info */}
						{modalType === Modals.OCServiceInformation && (
							<OCServiceInformation
								orderSKUID={orderSKUID}
								serviceID={_serviceID.current}
								handleOCServiceInformationAction={
									handleOCServiceInformationAction
								}
							/>
						)}
					</StyledModal>
				</Modal>
			</Box>
		) : (
			<Box className='no-sku-display-container'>
				{/* Content */}
				<Box className='no-sku-display-content'>
					{/* Icon */}
					<img
						src={require('../../../../assets/icons/orders/sku-icon.png')}
						alt='No Order SKUs'
					/>
					{/* Text */}
					<Box className='no-sku'>
						{/* Header */}
						<Typography component='h4'>No Order SKUs</Typography>
						{/* Text */}
						<Typography component='p'>
							This order has no order SKUs linked to it.
						</Typography>
					</Box>
				</Box>
			</Box>
		)

		async function publishActionCompleteMessage(
			ocServiceInfo: OCServiceInformationRequest
		) {
			var publishServiecBusMessageObj: PublishServiceBusMessageModel = {
				Message: `{ActionLogID: ${ocServiceInfo.ActionLogID}, IsSuccess: true}`,
				QueueOrTopicName:
					process.env.REACT_APP_ACTION_COMPLETED_TOPIC_NAME + '',
				ConnectionStringSettingName:
					process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING_SETTING + '',
			}
			var betaObj: BetaObject = {
				Content: await toBetaString(
					JSON.stringify(publishServiecBusMessageObj)
				),
			}
			await publishServiceBusMessage(betaObj)
				.unwrap()
				.then()
				.catch(async (error) => {
					if (error) {
						var errorMessage =
							`An error occurred when publishing Action Complete message. Request body: ` +
							JSON.stringify(publishServiecBusMessageObj) +
							` Error: ` +
							error?.data
						addErrorLog('Publish Service Bus Message:', errorMessage)
					}
				})
		}
	}

	// Detail panel of main datagrid
	const getDetailPanelContent = useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
		// eslint-disable-next-line react-hooks/exhaustive-deps
	>(({ row }) => <OrderSKUDisplay row={row} />, [])

	const getDetailPanelHeight = useCallback(() => 'auto', [])

	// Handle page navigation
	const handlePageNavigation = async (navType: string) => {
		// Set nav type loading
		setNavLoadingType(navType)

		// Page number
		var navPageNumber = currentPageNo
		var filtersToUse = orderFilterQuery

		// Check what was click
		switch (navType) {
			// Prev
			case 'prev':
				navPageNumber--
				break
			// Next
			case 'next':
				navPageNumber++
				break
			// Skip to first page1
			case 'first-page':
				navPageNumber = 1
				break
			// Skip to last page
			case 'last-page':
				navPageNumber = totalOrderPages
				break
			// Refresh
			case 'refresh':
				// Return to page 1 and clear filters
				navPageNumber = 1
				filtersToUse = {}
				setOrderFilterQuery(filtersToUse)
				setOrderFilters([])
				break
		}

		// Make call
		await getOrderDisplay({
			pageNo: navPageNumber,
			pageSize: currentPageSize,
			filters: filtersToUse,
		})

		// Dispatch
		dispatch(setOrderPageNo(navPageNumber))
		dispatch(setOrderFilter(filtersToUse))

		// End loading
		setNavLoadingType('')
	}

	// Handle filter call logic
	const handleFilterCall = async (orderFilters: OrderFilter[]) => {
		// Loading
		setFilterLoading(true)

		// Set filters in case
		setOrderFilters(orderFilters)

		// Loop through filters and build filter dictionary
		var builtFilters = createFiltersRecord(orderFilters)
		if (builtFilters) {
			// Set and make call
			setOrderFilterQuery(builtFilters)

			// Check if empty and reset global filter variable
			if (Object.keys(builtFilters).length === 0) {
				dispatch(resetOrderFilters())
			} else {
				dispatch(setOrderFilter(builtFilters))
			}
			await getOrderDisplay({
				pageNo: 1,
				pageSize: currentPageSize,
				filters: builtFilters,
			})
		}

		// Loading
		setFilterLoading(false)
	}

	const handleCloseOrderConfirmationModal = (applyFilter?: OrderFilter[]) => {
		if (applyFilter && applyFilter.length > 0) {
			handleFilterCall(applyFilter)
		}
		setOpenOrderModal(false)
	}

	// Get key by value for the key passed in
	const getKeyByValue = (value: string): string => {
		var returnKey = ''
		// Get mapping
		const keys = Object.keys(OrderFilterKeyMappings) as Array<
			keyof typeof OrderFilterKeyMappings
		>
		var findKey = keys.find((key) => OrderFilterKeyMappings[key] === value)
		if (findKey) {
			returnKey = findKey
		} else {
			returnKey = ''
		}
		return returnKey
	}

	// Build filter
	const createFiltersRecord = (
		filtersArray: OrderFilter[]
	): Record<string, string> => {
		return filtersArray.reduce((acc, filter) => {
			acc[getKeyByValue(filter.Key)] = filter.Value
			return acc
		}, {} as Record<string, string>)
	}

	// Handle open filter menu
	const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFilterAnchorEl(event.currentTarget)
	}

	// Handle close filter menu
	const handleCloseFilter = () => {
		setFilterAnchorEl(null)
	}

	// Handle confirm order
	const handleConfirmOrder = async (
		orderID: number,
		hasOrderNotes: boolean,
		orderNotes: string
	) => {
		// API Call
		await confirmOrder(orderID, hasOrderNotes, orderNotes)

		// Get latest orders back and close modal
		await getOrderDisplay({
			pageNo: currentPageNo,
			pageSize: currentPageSize,
			filters: orderFilterQuery,
		})
		handleCloseOrderConfirmationModal()
	}

	// Order Term
	const displayOrderTerm = (years: number, months: number): string => {
		const yearsString =
			years === 1 ? `${years} Year` : years > 1 ? `${years} Years` : ''
		const monthsString =
			months === 1 ? `${months} Month` : months > 1 ? `${months} Months` : ''

		// Convert to string and return
		return [yearsString, monthsString].filter(Boolean).join(' ')
	}

	return (
		<>
			<Box className='order-display-container'>
				{/* Filter toolbar */}
				<Box className='order-display-toolbar'>
					{/* Left - Heading and Filter */}
					<Box className='filter-toolbar'>
						{/* Icon and Heading */}
						<Box className='section-heading'>
							{/* Icon */}
							<img
								src={require('../../../../assets/icons/sideMenu/lmn/active/order-mgt.png')}
								alt='Orders'
							/>
							{/* Title */}
							<Typography component='h2'>Orders</Typography>
						</Box>
						{/* Divider */}
						<Divider orientation='vertical' />
						{/* Filter button */}
						<Box>
							{/* Button */}
							<Button
								startIcon={
									<Badge badgeContent={orderFilters.length} color='primary'>
										<FilterListOutlinedIcon />
									</Badge>
								}
								variant='outlined'
								aria-describedby={filterID}
								onClick={handleOpenFilter}>
								Filter
							</Button>
							{/* Filter popover */}
							<Popover
								id={filterID}
								open={openFilter}
								anchorEl={filterAnchorEl}
								onClose={handleCloseFilter}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}>
								<OrderFilters
									orderFilters={orderFilters}
									handleFilterCall={handleFilterCall}
								/>
							</Popover>
						</Box>
					</Box>
					{/* Right - Refresh */}
					<Box>
						<LoadingButton
							loading={navLoadingType.length > 0}
							startIcon={<RefreshOutlinedIcon />}
							loadingPosition='start'
							variant='outlined'
							onClick={() => handlePageNavigation('refresh')}>
							Refresh
						</LoadingButton>
					</Box>
				</Box>
				{/* Display options */}
				{callStatus === FilteredOrderStatus.Success ? (
					<>
						{/* Order display */}
						<StyledDataGrid
							rows={orderRows}
							columns={[
								...columns,
								{
									field: 'actions',
									type: 'actions',
									cellClassName: 'actions',
									headerName: 'Actions',
									width: 100,
									editable: false,
									align: 'center',
									hideable: false,
									getActions: ({ id, row }) => {
										return [
											<Box>
												{isPartnerAdmin &&
												(row.Comments.length > 0 ||
													row.OrderStatusID ===
														OrderStatuses.AwaitingPartnerApproval) ? (
													<>
														<Tooltip title='Order Options'>
															<IconButton
																id='order-options-button'
																onClick={(e) => {
																	openOrderOptionsMenu(e, Number(id), row)
																}}>
																<SettingsIcon />
															</IconButton>
														</Tooltip>
														<Popover
															className='order-options-menu'
															id='order-options-menu'
															anchorEl={orderAnchorEl}
															open={openOrderMenu && orderID === Number(id)}
															onClose={handleCloseOrderMenu}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'center',
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'center',
															}}>
															{/* Confirm Order & Change Order Type */}
															{orderNotes.length === 0 ? (
																<>
																	{/* Still in QA and wait for Finance 3.7 */}
																	{/* {OrderType[
																		row.OrderType as keyof typeof OrderType
																	] === OrderType.New && (
																		<MenuItem
																			onClick={() =>
																				handleOpenOrderModal(
																					Modals.ChangeOrderType
																				)
																			}>
																			Change Order Type
																		</MenuItem>
																	)} */}
																	<MenuItem
																		onClick={() =>
																			handleOpenOrderModal(Modals.ConfirmOrder)
																		}>
																		Confirm Order
																	</MenuItem>
																</>
															) : (
																orderNotes.length > 0 &&
																orderStatusID !==
																	OrderStatuses.AwaitingPartnerApproval && (
																	<MenuItem
																		onClick={() => {
																			handleOpenOrderModal(Modals.OrderNotes)
																		}}>
																		View Order Notes
																	</MenuItem>
																)
															)}
														</Popover>
													</>
												) : (
													<Tooltip title='Order has been confirmed and there are no order notes to view.'>
														<DoNotDisturbIcon />
													</Tooltip>
												)}
											</Box>,
										]
									},
								},
							]}
							editMode='row'
							checkboxSelection={false}
							slots={{
								footer: CustomFooter,
								toolbar: CustomToolbar,
							}}
							slotProps={{
								toolbar: {
									showFilter: false,
								},
							}}
							getRowId={(row) => row.OrderID}
							autoHeight={orderRows.length > 0}
							sx={
								orderRows.length === 0
									? { minHeight: '400px' }
									: { height: 'auto' }
							}
							rowSelection={false}
							getDetailPanelHeight={getDetailPanelHeight}
							getDetailPanelContent={getDetailPanelContent}
							hideFooterRowCount
							disableColumnMenu
						/>
						<Modal open={openOrderModal} onClose={handleCloseOrderModal}>
							<StyledModal width={modalWidth}>
								{/* Order Notes */}
								{modalType === Modals.OrderNotes && (
									<SREEmergencyCallingDisplay />
								)}
							</StyledModal>
						</Modal>
					</>
				) : (
					// Custom empty/error display
					<OrderStatusCardDisplay status={callStatus} />
				)}
			</Box>
			{/* Modal */}
			<Modal
				open={openOrderModal}
				onClose={() => handleCloseOrderConfirmationModal([])}>
				<StyledModal width={400}>
					{modalType === Modals.OrderNotes && (
						<ViewOrderNotes orderNotes={orderNotes} />
					)}

					{modalType === Modals.ChangeOrderType && (
						<ChangeOrderType
							orderID={orderID}
							onClose={handleCloseOrderConfirmationModal}
						/>
					)}

					{modalType === Modals.ConfirmOrder && (
						<ConfirmOrder
							orderID={orderID}
							handleConfirmOrder={handleConfirmOrder}
						/>
					)}
				</StyledModal>
			</Modal>
			{/* Loading Backdrop */}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={navLoadingType.length > 0 || filterLoading}
			/>
		</>
	)
}

export default OrderDisplay
